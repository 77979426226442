// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "channels"
import * as bootstrap from 'bootstrap';
import "../stylesheets/application"
import ahoy from "ahoy.js"
require('toastr')
import toastr from "toastr"
require('jquery')
require("@nathanvda/cocoon")

Rails.start()
Turbolinks.start()
window.bootstrap = bootstrap;
window.Rails = Rails;
window.ahoy = ahoy;
window.toastr = toastr;

function onl() {
  // Toastr
  var error_data = document.querySelector('[data-error]')
  if (error_data != null) {
    toastr.error(error_data.dataset.error)
    error_data.outerHTML = ''
  }
  var success_data = document.querySelector('[data-success]')
  if (success_data != null) {
    toastr.success(success_data.dataset.success)
    success_data.outerHTML = ''
  }
  // Ahoy tracking for page view
  var page_show = document.querySelector('#page_show')
  if (page_show != null) {
    window.ahoy.track(
      'view',
      {
        'page_id': page_show.getAttribute('data-page-id')
      }
    )
  }
  // Ahoy tracking for buttons on page
  var buttons_collection = document.querySelectorAll('.btn[data-button-id]')
  buttons_collection.forEach(function (button) {
    button.addEventListener('click', function () {
      window.ahoy.track(
        'click_button',
        {
          'page_id': button.getAttribute('data-page-id'),
          'button_id': button.getAttribute('data-button-id')
        }
      )
    })
  })

  var copy_to_clipboard_buttons = document.querySelectorAll('.copy-to-clipboard')
  copy_to_clipboard_buttons.forEach(function(button) {
    var copy_text = button.dataset.copyText
    button.addEventListener('click', (e) => {
      navigator.clipboard.writeText(copy_text)
      window.toastr.success("Текст скопирован!")
      e.preventDefault()
    })
  })

  var hidden_token = document.querySelector('.hidden[data-edit-token]');
  if (hidden_token != null) {
    window.Rails.ajax({
      url: window.location.href,
      type: 'get',
      dataType: 'script',
      data: new URLSearchParams({'edit_token': hidden_token.getAttribute('data-edit-token')}).toString()
    });
  }

  if (document.querySelectorAll('.toast').length > 0) {
    var toastElList = [].slice.call(document.querySelectorAll('.toast'));
    var toastList = toastElList.map(function (toastEl) {
      return new window.bootstrap.Toast(toastEl);
    });
    toastList.map(function (toastEl) {
      toastEl.show();
    });
  }
}

document.addEventListener("turbolinks:load", function() {onl();});
